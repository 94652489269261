<template>
  <!-- <dt :class="config.size_value">
    <CSpinner v-if="!options" color="secondary" size="sm" />
    <span v-else-if="!config.form.active || config.fconfig.readonly">
      {{ SelectedLabel }}
    </span>
    <CVSelect
      v-else-if="config.form.active && !config.fconfig.readonly"
 
      v-model="config.form.formData[config.fname]"
      :options="options"
      :is-valid="config.is_valid(config.fname)"
      :invalid-feedback="config.invalid_feedback(config.fname)"
      :description="config.fconfig.description"
    />
  </dt> -->
  <dt :class="config.size_value">
    <CSpinner v-if="!options || !master_options" color="secondary" size="sm" />
    <span v-else-if="!config.form.active || config.fconfig.readonly">
      {{ SelectedChkValues }}
    </span>
    <div v-else-if="config.form.active && !config.fconfig.readonly">
      <div v-if="config.fconfig.search">
        <CInput horizontal v-model="options_search" placeholder="cerca...">
          <template #append-content
            ><CIcon name="cil-magnifying-glass" />
          </template>
        </CInput>
      </div>
      <CRow>
        <CCol
          md="3"
          v-for="(moption, moptionIndex) in master_options"
          :key="moptionIndex"
        >
          <h5>{{ moption.label }}</h5>
          <div
            v-for="(option, optionIndex) in FilteredOptions(moption.value)"
            :key="optionIndex"
          >
            <CInputCheckbox
              :label="option.label"
              :value="option.value"
              :inline="true"
              :checked="value.includes(option.value)"
              @input="UpdateValue($event)"
            />
          </div>
        </CCol>
      </CRow>

      <small
        v-if="config.fconfig.description"
        class="form-text text-muted w-100"
        v-text="config.fconfig.description"
      ></small>
    </div>
    <br />
  </dt>
</template>

<script>
export default {
  props: ["config"],
  data() {
    return {
      options: null,
      master_options: null,
      options_search: "",
      value: this.config.form.formData[this.config.fname] || [],
    };
  },
  created() {
    this.config.fconfig.options.then((opts) => {
      this.options = opts;
    });
    this.config.fconfig.master_options.then((opts) => {
      this.master_options = opts;
    });
  },
  watch: {
    "config.fconfig.options"() {
      //   console.log(this.config.fconfig.options);
      //  this.options = null
      this.config.fconfig.options.then((opts) => {
        this.options = opts;
      });
    },
  },
  methods: {
    UpdateValue(e) {
      // console.log("CheckBox Updated")
      // console.log("Selected Values", this.options)
      // console.log("Selected event", e.target.checked, e.target.value)
      if (e.target.checked) {
        this.value.push(parseInt(e.target.value));
      } else {
        let index = this.value.indexOf(parseInt(e.target.value));
        if (index !== -1) {
          this.value.splice(index, 1);
        }
      }
      // console.log("Selected Values list", this.value)
      this.$emit("change", this.value, e);
    },
    FilteredOptions(macro_id) {
      let self = this;
      let res = this.options.reduce(function (map, obj) {
        if (obj.macro_id == macro_id) {
          if (
            self.options_search == "" ||
            obj.label
              .toLowerCase()
              .indexOf(self.options_search.toLowerCase()) >= 0
          ) {
            map.push(obj);
          }
        }

        return map;
      }, []);
      return res;
    },
  },
  computed: {
    SelectedChkValues() {
      let res = [];
      for (var i = 0; i < this.options.length; i++) {
        if (this.value.includes(this.options[i].value)) {
          res.push(this.options[i].label);
        }
      }
      return res.join(",");
    },
    // FilteredOptions() {
    //   let self = this;
    //   let res = this.options.reduce(function (map, obj) {
    //     if (
    //       self.options_search == "" ||
    //       obj.label.toLowerCase().indexOf(self.options_search.toLowerCase()) >=
    //         0
    //     ) {
    //       map.push(obj);
    //     }
    //     return map;
    //   }, []);
    //   return res;
    // },
  },
};
</script>
